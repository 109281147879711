import {useMutation} from "@apollo/client";
import React, {Fragment} from "react";
import {useEffect} from "react";
import {useState} from "react";
import {Button, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {localStorage, window} from "window-or-global";

import LogoMonoImage from "../images/logo.svg";
import AuthLogo from "../images/icon_authent.svg";
import AuthOnLogo from "../images/icon_authenticator on.svg";
import ScanLogo from "../images/icon_scan.svg";
import {createVerificationMutation, generateTotpMutation, logoutMutation, removeTotp} from "./logic/user";
import OtpInput from "./otp-input";
import Modal from "./bootstrap/modal";

import {Fade as Burger} from "./react-burger";
import LogoutIcon from "../images/logout.svg";
import AuthIcon from "../images/icon_authenticator.svg";
import { BottomLeft, BottomRight, TopLeft, TopRight } from "./corners";

const Mfa = (props) => {
  const [showScan, setShowScan] = useState(false);
  const [imageGen, setImageGen] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [requestCode, setRequestCode] = useState(null);
  const [totpMutation] = useMutation(generateTotpMutation);
  const {user, userQueryRefetch} = props;
  const [showDisableAuth, setShowDisableAuth] = useState(false);

  const handleGenerateQR = async (userId) => {
    const QRCode = require("qrcode");
    const result = await totpMutation({
      variables: {
        id: userId,
      },
    });

    const otp = result.data.classMethods.UserAuth.generateTOTP.otpAuthURL;

    QRCode.toDataURL(otp, function(err, data) {
      setImageGen(data);
      setShowScan(true)
      return data;
    });
  };

  const MfaQrScanModal = () => {
    const [mfaSuccess, setMfaSuccess] = useState(false);

    const ScanDisplay = () => {
      const [createVerification] = useMutation(createVerificationMutation);

      const handleNext = () => {
        createVerification({
          variables: {
            type: "TEMP_TOTP",
            userId: user?.id,
            name: "TOTP Registration",
          },
          awaitRefetchQueries: true,
        }).then(result => {
          const {data} = result;
          setRequestCode(data?.classMethods?.Verification?.createVerification?.id);
          setShowConfirmation(true);
        });
      };

      return (
        <Fragment>
          <div className="mfa-scan-qr-body">
            <img src={ScanLogo}/>
            <div className="heading">{"Scan QR Code"}</div>
            <p>{"Use an authenticator app from your phone to scan. After you scan the QR code, choose 'Next'."}</p>
            <div className="qr-code-container">
            <div className="qr-code-container-inner">
              <TopLeft/>
              <TopRight/>
              <BottomLeft/>
              <BottomRight/>
              {imageGen && <img src={imageGen} alt="mfa-qr-code" className="qr-code"/>}
            </div>
          </div>
          </div>
          <div className="mfa-scan-qr-buttons">
            <Button variant="black-text" onClick={() => setShowScan(false)}>{"Back"}</Button>
            <Button className="btn-yellow" onClick={() => handleNext()}>{"Next"}<i className="fas fa-angle-right"/></Button>
          </div>
        </Fragment>
      );
    };

    const ConfirmationCodeInput = () => {
      const [otp, setOtp] = useState(Array(6).fill(""));
      const [otpError, setOtpError] = useState(null);
      const [otpLoading, setOtpLoading] = useState(false);

      const handleOtpSubmit = async (otp) => {
        setOtpLoading(true);
        const apiPath = process.env.BACKEND_URL;
        const data = await fetch(`${apiPath}rest.api/verify/${requestCode}:${otp}`, {
          method: "GET",
        });

        const parsedData = await data.json();

        if (parsedData.success) {
          setMfaSuccess(true);
        } else {
          setOtpError("Invalid code");
        }

        setOtpLoading(false);
      };

      return (
        <Fragment>
          <div className="mfa-activate-body">
            <div className="heading">{"Enter Confirmation Code"}</div>
            <p>{"Please enter the confirmation code you see on your authentication app."}</p>
            <OtpInput
              count={6}
              value={otp}
              onChange={value => {
                setOtp(value);
                setOtpError(null);
              }}
            />
            {otpError && <div className="mfa-otp-input-error">{otpError}</div>}
          </div>
          <div className="mfa-activate-buttons">
            <Button
              variant="text-black"
              className="totp-activate"
              onClick={() => handleOtpSubmit(otp.join(""))}
            >
              {"Activate"} <i className="fas fa-angle-right"/>
            </Button>
          </div>
        </Fragment>
      );
    };

    const MfaEnrollmentSuccess = () => {
      return (
        <div className="mfa-scan-qr-success">
          <i className="fa fa-check"/>
          <p>{"Account Secured!"}</p>
          <Button
            className="btn-darkblue"
            onClick={() => {
              setShowScan(false);
              if (userQueryRefetch) {
                userQueryRefetch();
              }
            }}
          >{"Close"}</Button>
        </div>
      );
    };

    const MODAL_TITLE = mfaSuccess ? "Authentication Successful" : showConfirmation ? "Confirmation Code" : "Scan QR Code"

    return (
      <Modal
        title={MODAL_TITLE}
        show={showScan}
        className="mfa-scan-qr-modal"
        onClose={() => setShowScan(false)}
      >
        {mfaSuccess && <MfaEnrollmentSuccess/>}
        {!mfaSuccess &&
          <div className="mfa-scan-qr-content">
            {!showConfirmation && <ScanDisplay/>}
            {showConfirmation && <ConfirmationCodeInput/>}
          </div>
        }
      </Modal>
    );
  };

  const DisableMfaModal = () => {
    const [removeTotpNutation] = useMutation(removeTotp);

    const handleRemoveTotp = async () => {
      await removeTotpNutation({
        variables: {
          id: user.id,
        },
      });

      if (userQueryRefetch) {
        userQueryRefetch();
      }
    };

    return (
      <Modal
        title="Disable App Authentication"
        show={showDisableAuth}
        className="mfa-disable-auth-modal"
        footer={
          <div className="mfa-disable-auth-buttons">
            <Button variant="black-text" onClick={() => setShowDisableAuth(false)}>{"Cancel"}</Button>
            <Button variant="danger" onClick={handleRemoveTotp}>{"Confirm"}</Button>
          </div>
        }
      >
        <div className="disable-auth-content">
          <p>{"Are you sure to disable authentication app?"}</p>
        </div>
      </Modal>
    );
  };

  const MfaHeader = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [logout] = useMutation(logoutMutation);

    const onHandleLogout = async () => {
      await logout();
      localStorage.removeItem("authToken");
      window.location.href = "/login/";
    };

    const isActive = (path) => window.location.pathname === path;

    return (
      <Navbar bg="white" variant="light" expand="lg" className="mfa-header">
        <Navbar.Brand href={process.env.PORTAL_URL}>
          <img src={LogoMonoImage} alt="logo" className="header-logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <Burger direction="right"/>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto font-white align-items-center">
            <NavDropdown
              className="mfa-header-dropdown"
              title={<Burger direction="right"/>}
              onClick={() => setShowMenu(prev => !prev)} show={showMenu}
              menuAlign={{lg: "right"}}
            >
              <NavDropdown.Item href="/mfa" active={isActive("/mfa")}><img src={AuthIcon}/>{"2-Step Verification"}</NavDropdown.Item>
              <NavDropdown.Item onClick={() => onHandleLogout()}><img src={LogoutIcon}/>{"Logout"}</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className="ph-link hidden-on-expand" href="/mfa">{"2-Step Verification"}</Nav.Link>
            <Nav.Link className="ph-link hidden-on-expand" onClick={() => onHandleLogout()}>{"Logout"}</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  };

  return (
    <div className="mfa-container">
      <MfaQrScanModal/>
      <DisableMfaModal/>
      <MfaHeader/>
      <main className="mfa-main">
        <p className="mfa-welcome">{`Welcome ${user?.firstName} ${user?.lastName}!`}</p>
        <section className="mfa-content-panel">
          {!user.hasUserTOTP &&
            <Fragment>
              <div>{"Help Protect Your Account"}</div>
              <p>{"If we notice an attempted login from a device we don't recognize, we'll ask for your password and a verification code."}</p>
              <hr/>
              <div className="mfa-content-authenticator">
                <img src={AuthLogo} className="mfa-auth-logo"/>
                <div className="mfa-auth-text">
                  <div>{"Authentication App"}</div>
                  <p>
                    <span>{"Recommended"}</span>{"• Use an app like Google Authenticator, Mircrosoft Authenticator, or Authy to generate verification codes for more protection."}
                  </p>
                  <Button
                    className="btn-yellow setup-verification"
                    disabled={!user}
                    onClick={() => {
                      handleGenerateQR(user.id)
                    }}
                  >{"Set up 2-step verification"}</Button>
                </div>
              </div>
            </Fragment>
          }
          {user.hasUserTOTP &&
            <Fragment>
              <div>{"Authentication is On"}</div>
              <p>{"If we notice an attempted login from a device we don't recognize, we'll ask for your password and a verification code."}</p>
              <hr/>
              <div className="mfa-content-authenticator">
                <img src={AuthOnLogo} className="mfa-auth-logo"/>
                <div className="mfa-auth-text">
                  <div>{"Authentication App"}</div>
                  <p>
                    {"You'll receive a login code via an authenticator app"}
                  </p>
                  <Button variant="danger" className="red vw-button" onClick={() => setShowDisableAuth(true)}>{"Disable Authentication App"}</Button>
                </div>
              </div>
            </Fragment>
          }
        </section>
      </main>
    </div>
  );
};

export default Mfa;
